// src/App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Box } from '@mui/material';
import LoginPage from './pages/Login';
import RegisterPage from './pages/Register';

function App() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
		<Route path="/register" element={<RegisterPage />} />
      </Routes>
    </Box>
  );
}

export default App;