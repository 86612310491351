import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#008080', // Teal - Primary Brand Color
      light: '#339595',
      dark: '#005959',
    },
    secondary: {
      main: '#4169E1', // Royal Blue - Secondary Brand Color
      light: '#6687e7',
      dark: '#2d499d',
    },
    background: {
      default: '#f5f5f5',
      paper: '#ffffff',
    },
  },
  typography: {
    fontFamily: [
      'Montserrat',
      'Open Sans',
      'Arial',
      'sans-serif'
    ].join(','),
    h1: {
      fontFamily: 'Montserrat',
      fontWeight: 600,
    },
    h2: {
      fontFamily: 'Montserrat',
      fontWeight: 600,
    },
    body1: {
      fontFamily: 'Open Sans',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: 'none',
          padding: '8px 24px',
        },
      },
    },
  },
});

export default theme;