// src/config/api.js
const API_CONFIG = {
    baseURL: process.env.REACT_APP_API_URL || 'https://api.skillecho.com/api/v1',
    endpoints: {
        register: '/register',
        login: '/login',
        logout: '/logout',
        profile: '/profile'
    }
};

// Log the API URL for debugging
console.log('API Base URL:', API_CONFIG.baseURL);

export default API_CONFIG;